// 定义一个混入对象
const tabMixin = {
  data() {
    return {
      tabs: [
        // { name: '系统消息' },
        // { name: '截图管理' },
        { name: '系统消息' }
      ]
    };
  },
  created() {},
  methods: {
    selectCurrent(index) {
      switch (index) {
        case 0:
          this.$router.push({ path: '/all-message' });
          break;
      }
    }
  }
};
export default tabMixin;
