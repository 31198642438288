<template>
  <div class="bg-blue full-height text-lightblue p-md">
    <back /><!-- :url="getUrl"  -->
    <!-- <tab :tabs="tabs" :initTab="0" /> -->
    <div class="flex items-center justify-between m-t-sm m-b-sm">
      <div class="fl-menu flex items-center">
        <span
          :class="[{ current: activeMenu === 0 }, 'pointer']"
          @click="toggleActive(0)"
          >未读消息<!-- ({{noReadNumFormat}}) --></span
        >
        <span
          :class="[{ current: activeMenu === 1 }, 'pointer']"
          @click="toggleActive(1)"
          >已读消息<!-- ({{readNumFormat}}) --></span
        >
      </div>
      
    </div>
    <div class="flex justify-between  text-lightblue">
      <div class="flex flex-one item-center flex-wrap">
        <div class="m-b-xs">
        <span class="flex-s m-r-xs" v-show="$g.isDepart">省份：</span>
        <a-select
          placeholder="请选择省份"
          v-model="regionId"
          @change="changeProvince"
          class="m-r-sm  search-mx-wd-1"
          v-show="$g.isDepart"
        >
          <a-select-option
            v-for="item in provinceList"
            :key="item.regionCode"
            :value="item.regionCode"
          >
            {{ item.alias }}
          </a-select-option>
        </a-select>
        </div>
        <div class="m-b-xs">
        <span class="flex-s m-r-xs">管辖单位：</span>
        <a-cascader
          :options="organizationList"
          :display-render="displayRender"
          v-model="organizationId"
          class="m-r-sm   search-mx-wd-1"
          expand-trigger="hover"
          change-on-select
          placeholder="管辖单位"
          @change="selectOrganization"
        />
        </div>
        <div class="m-b-xs">
        <span class="flex-s m-r-xs">摄像机名称：</span>
        <a-input
          placeholder="请输入摄像机名称"
          class="m-r-sm  search-mx-wd-1 "
          v-model="cameraName"
        />
        </div>
        <div class="m-b-xs">
        <span class="flex-s m-r-xs">日期：</span>
        <a-range-picker
          v-model="turnDateRange"
          format="YYYY-MM-DD HH:mm:ss"
          class="m-r-sm search-date-picker "
          showTime
          @change="changeDataRange"
        />
        </div>
        <div class="m-b-xs">
        <span class="flex-s m-r-xs">报警内容：</span>
        <a-input
          placeholder="请输入报警内容"
          class="m-r-sm  search-mx-wd-1 "
          v-model="alarmName"
        />
        </div>
        <a-button type="primary" class="m-r-sm btn-search" @click="handleSearch">
          搜索
        </a-button>
        <a-button class="btn-reset" @click="resetHandle">
          重置
        </a-button>
      </div>
      <div class="flex">
        <span class="span-line m-l-md m-r-md"></span>
        <a-button type="button" class="btn-export" @click="handleReadAll" v-if="activeMenu == 0">
            全部已读
        </a-button>
        <a-button type="button" class="btn-export" @click="handleDelAll" v-if="activeMenu == 1" >
            全部删除
        </a-button>
      </div>
    </div>
    <div style="height:calc(100% - 80px);">
      <!-- <div class="fl-menu">
        <span
          :class="[{ current: activeMenu === 0 }, 'pointer block m-b-xs']"
          @click="activeMenu = 0"
          >系统消息</span
        >
        <span
          :class="[{ current: activeMenu === 1 }, 'pointer block']"
          @click="activeMenu = 1"
          v-if="false"
          >运行报告</span
        >
      </div> -->
      <div class="full-height">
        <template>
          <!-- <system-message v-if="activeMenu === 0"/>
          <read-message v-else-if="activeMenu === 1"/> -->
          <!-- <run-report v-else-if="activeMenu === 1" /> -->
          <a-table
            :rowKey="(row) => row.id"
            :components="resibleTableHeader"
            :columns="columns"
            :data-source="messageList"
            :pagination="paginationOpt"
            :scroll="scroll"
            :loading="loading"
            bordered
          >
                <template slot="action" slot-scope="row">
                    <a-tooltip placement="top" v-if="activeMenu === 0">
                      <template slot="title">
                        <span>已读</span>
                      </template>
                      <a-icon type="check" @click="readRow(row)"/>
                    </a-tooltip>
                    <a-tooltip placement="top" v-else-if="activeMenu === 1">
                      <template slot="title">
                        <span>删除</span>
                      </template>
                      <a-icon type="rest" @click="deleteRow(row)"/>
                    </a-tooltip>
                </template>
          </a-table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// import Tab from '@/components/Tab';
import messageTabs from '@/components/mixins/messageTabs';
import Back from '@/components/Back';
// import SystemMessage from './components/SystemMessage';
// import ReadMessage from './components/ReadMessage';
import { getStorage } from '@/tools';
import { mapState, mapActions } from 'vuex';
import moment from 'moment';
import ResizableTable from '@/components/mixins/resizeableTable';
// import RunReport from './components/RunReport';
const formatNumb = (num) => {
  let newNum = String(num);
  if(newNum.length>4){
    return newNum.substring(0,1)+'..'+newNum.substring(newNum.length-1);
  }
  return newNum;
}
export default {
  name: 'Message',
  mixins: [messageTabs,ResizableTable],
  data() {
    return {
      activeMenu: 0,
      loading: false,
      columns: [
        {
          title: '序号',
          dataIndex: 'messageIndex',
          customRender: (text, record, index) => index + 1,
          width: 60,
          ellipsis: true,
        },
        {
          title: '消息类型',
          dataIndex: 'messageType',
          width: 80,
          ellipsis: true,
        },
        {
          title: '区域',
          dataIndex: 'regionName',
          width: 80,
          ellipsis: true,
        },
        {
          title: '信息内容',
          dataIndex: 'content',
          width:260,
          ellipsis: true,
        },
        {
          title: '管辖单位',
          dataIndex: 'organizationName',
          width: 120,
          ellipsis: true,
        },
        {
          title: '关联摄像机',
          dataIndex: 'cameraName',
          width: 180,
          ellipsis: true,
          customRender(text){
            return text ? text : '--'
          }
        },
        {
          title: '接收时间',
          dataIndex: 'gmtCreate',
          width: 100,
          ellipsis: true,
        },
        {
          title: '操作',
          key:'action',
          class:'column-center',
          width:70,
          scopedSlots:{customRender:'action'}
        }
      ],

      paginationOpt: {
        current: 1, // 默认当前页数
        defaultPageSize: 10, // 默认当前页显示数据的大小
        total: 4, // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ['5', '10', '15', '20'],
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) => {
          this.paginationOpt.current = 1;
          this.paginationOpt.defaultPageSize = pageSize;
          this._getMessageList();
        },
        // 改变每页数量时更新显示
        onChange: (current, size) => {
          this.paginationOpt.current = current;
          this.paginationOpt.defaultPageSize = size;
          this._getMessageList();
        },
      },
      scroll: { y: window.innerHeight - 370, x:'100%'},
      regionId: undefined,
      organizationId: [],
      turnDateRange: null,
      alarmName: '',
      cameraName:'',
      messageInfoList:[]
    };
  },
  components: {
    Back,
    // SystemMessage,
    // ReadMessage
    // Tab,
    // RunReport,
  },
  computed: {
    ...mapState({
      messageList: (state) => state.message.messageList,
      readNum: (state) => state.message.readNum,
      noReadNum: (state) => state.message.noReadNum,
      provinceList: (state) => state.collect.provinceList,
      organizationList: (state) => state.collect.organizationList,
    }),
    getUrl() {
   
      return getStorage('historyURL') || '/home';
    },
    noReadNumFormat() {
      return formatNumb(this.noReadNum);
    },
    readNumFormat() {
      return formatNumb(this.readNum);
    }
  },
  watch: {},
  mounted() {
    this.getProvince({ regionLevel: 1 });
    this.getOrganationList();
    this._getMessageList();
    this.getMessageCount();
  },
  methods: {
    ...mapActions(['getMessageList', 'getProvince', 'getOrganationList','getMessageCount']),
    selectHandle(index) {
      this.selectCurrent(index);
    },
    toggleActive(activeMenu) {
      this.activeMenu = activeMenu;
      this.resetHandle();
    },
    _getMessageList() {
      let obj = {
        currPage: this.paginationOpt.current,
        pageSize: this.paginationOpt.defaultPageSize,
        organizationId:
          this.organizationId.length > 0
            ? this.organizationId[this.organizationId.length - 1]
            : '',
        regionCode: this.regionId,
        content: this.alarmName,
        startTime: this.turnDateRange
          ? moment(this.turnDateRange[0]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        endTime: this.turnDateRange
          ? moment(this.turnDateRange[1]).format('YYYY-MM-DD HH:mm:ss')
          : '',
        cameraName:this.cameraName,
        alreadyRead:this.activeMenu
      };
      this.loading = true;
      this.getMessageList(obj).then((data) => {
        if (data.code === 200) {
          this.loading = false;
          // this.messageInfoList = data.data;
          this.paginationOpt.total = data.total;
        }
      });
    },
    changeProvince(val) {
      this.regionId = val;
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    selectOrganization(value) {
      this.organizationId = value;
    },
    changeDataRange(date) {
      // console.error(date, dateString);
      this.turnDateRange = date;
    },
    handleSearch() {
      this.paginationOpt.current = 1;
      this.paginationOpt.defaultPageSize = 10;
      this._getMessageList();
    },
    resetHandle() {
      this.regionId = undefined;
      this.organizationId = [];
      this.turnDateRange = null;
      this.alarmName = '';
      this.cameraName = '';
      this.handleSearch();
    },
    readRow(row) {
      this.$confirm({
        title: '提示',
        content: '是否标记为已读?',
        onOk: () => {
           let data = {id:row.id};
           this.$api.message.updateMessage(data).then(res=>{
             if(res.code === 200) {
               this.$message.success('标记已读成功！');
               this.handleSearch();
               this.getMessageCount();
             }
           })
        },
        onCancel: () => {},
      });
    },
    deleteRow(row) {
      this.$confirm({
        title: '提示',
        content: '你确定删除该消息吗?',
        onOk: () => {
           let data = {id:row.id};
           this.$api.message.batchDelete(data).then(res=>{
             if(res.code === 200) {
               this.$message.success('该消息删除成功！');
               this.handleSearch();
               this.getMessageCount();
             }
           })
        },
        onCancel: () => {},
      });
    },
    //已读所有
    handleReadAll() {
      this.$confirm({
        title: '提示',
        content: '是否标记所有为已读?',
        onOk: () => {
           this.$api.message.updateMessage().then(res=>{
             if(res.code === 200) {
               this.$message.success('标记所有已读成功！');
               this.resetHandle();
               this.getMessageCount();
             }
           })
        },
        onCancel: () => {},
      });
    },
    //删除所有
    handleDelAll() {
      this.$confirm({
        title: '提示',
        content: '你确定删除所有消息吗?',
        onOk: () => {
           this.$api.message.batchDelete().then(res=>{
             if(res.code === 200) {
               this.$message.success('消息删除成功！');
               this.resetHandle();
               this.getMessageCount();
             }
           })
        },
        onCancel: () => {},
      });
    }
  },
};
</script>

<style scoped>
.fl-menu {
  width: 200px;
}
.fl-menu span {
  background: rgba(150, 185, 228, 0.1);
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #86b3e9;
}
.fl-menu span:first-child {
  margin-right: 1px;
}
.fl-menu span.current,
.fl-menu span:hover {
  background: linear-gradient(0deg, #1c5bb2, #0f283d);
  border: 1px solid;
  border-image: linear-gradient(0deg, #4679bf, #143354) 1 1;
  color: #fff;
}
</style>
